<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Holiday Groups</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Holiday Groups</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_MANAGE_HOLIDAYS')"
                        :to="{name: 'create-holiday-group'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Holiday Group
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link
                            class="circle"
                            v-b-tooltip.hover title="Edit"
                            :to="{ name: 'edit-holiday-group', params: { id:  row.item.id}}"
                        >
                            <font-awesome-icon
                                v-if="hasPermission('CAN_MANAGE_HOLIDAYS')"
                                icon="pen"
                            ></font-awesome-icon>
                        </router-link>
                    </span>
                </template>
                <template v-slot:cell(name)="row">
                    <router-link class="nav-link" style="color:black"
                        :to="{ name: 'holidays', params: { holiday_group:  row.item.id}}"
                    > {{row.item.name}}
                    </router-link>
                </template>
            </b-table>
             
        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "",
            },
            success: false,
            updated: false,
            api_error: "",
            sortBy: "",
            sortDesc: false,
            fields: [
                { key: "name", sortable:true },
                { key: "actions", label: "Actions" }
            ],
            items: null,
            filter: null,
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_MANAGE_HOLIDAYS");
        this.onPageLoad();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        onFiltered() {
            this.startProgressBar();
            this.urlBody.query = this.filter;
            var query;
            query = this.deleteEmptyKeys(this.urlBody);
            this.getHolidayGroups(query);
         
        },

        onPageLoad:function(){
            this.startProgressBar();
            var urlBody =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.deleteEmptyKeys(this.urlBody);    
            this.getHolidayGroups(urlBody); 
        },

        getHolidayGroups: function(params) {
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios
                .get(this.$api.get_holiday_groups, query)
                .then(response => {
                    this.items = response.data.data;
                    this.api_error = "";
                    this.updateProgressBar(true);
                    this.$router
                        .push({
                            path: this.$route.params[0],
                            query: this.deleteEmptyKeys(params)
                        }).catch(()=>{})
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.items = [];
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            this.api_error = "";
            this.getHolidayGroups(urlBody)
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>